'use client';

import { useState } from 'react';
import { GraphqlProvider } from 'contexts';
import { AuthContextProvider } from 'contexts/auth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AlertContextProvider } from './_context/alertContext';
import { HelpModalProvider } from './_context/HelpContext';
import { initAmplitude } from 'app/_tracking';
import { CommunityContextProvider } from 'contexts/communityApp';
import { TrackingContextProvider } from 'app/_tracking/context';
import { MembersProvider } from './_context/MembersContext';
import { initHotjar } from 'app/_tracking/hotjar';

import { Toaster } from 'components/ui/sonner';

import { RouteListenerProvider } from './_context/RouteListener';
import { TelegramContextProvider } from 'app/_context/TelegramContext';

initAmplitude();
initHotjar();

export function ContextProviders({ children, isTelegramMiniApp }: {
  children: React.ReactNode;
  isTelegramMiniApp: boolean;
}) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: 60 * 1000 * 5 //5 minutes
          }
        }
      })
  );
  return (
    <GraphqlProvider>
      <AuthContextProvider>
        <CommunityContextProvider>
          <TelegramContextProvider isEnabled={isTelegramMiniApp}>
            <AlertContextProvider>
              <TrackingContextProvider>
                <QueryClientProvider client={queryClient}>
                  <RouteListenerProvider>
                    <HelpModalProvider>
                      <MembersProvider>
                        <Toaster />
                        {children}
                      </MembersProvider>
                    </HelpModalProvider>
                  </RouteListenerProvider>
                </QueryClientProvider>
              </TrackingContextProvider>
            </AlertContextProvider>
          </TelegramContextProvider>
        </CommunityContextProvider>
      </AuthContextProvider>
    </GraphqlProvider>
  );
}
